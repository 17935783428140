import React from "react"
import Layout from "@components/layout";

// 言語用Jsonファイル
import { LangSetting } from "@components/language-setting.js";
import langEn from "@content/locales/en/regulation.json";
import langJa from "@content/locales/ja/regulation.json";

// 共通Link用ファイル
import { LinkSetting } from "@components/link-setting.js";

import { SeoContents } from "@components/seo-data.js" // Seo情報設定

const Regulation = ( props ) => {
  
  // 言語設定
  const setLang = LangSetting( props, langEn, langJa ).SetLang;
  const postLang = LangSetting( props, langEn, langJa ).PostLang;

  // 構造化データ用配列
  // 作成・更新日
  const date = {modified: process.env.MODIFIED};

  // Seo情報設定
  const pathName = 'regulation'; // ファイル名
  const title = postLang["page-title"];
  const description = postLang["page-description"];
  const keywords = postLang["page-keywords"];
  const seoTag = SeoContents( pathName, setLang, title, description, keywords, date);
  
  // リンク設定
  const signPath1 = LinkSetting( setLang ).Register;
  
  return (
  <>
    {seoTag}
    <Layout>
      <div className="FV pt-5 pb-8 bg-fixed" langType={setLang}>
        <div className="max-w-xl mx-auto px-6 md:max-w-5xl md:pt-5 pt-2 md:flex">
          <div className=" text-left h1_filed">
            <h1 className="md:text-[50px] text-[35px] font-semibold">{postLang["isa-h1"]}</h1>
            <div className="text-base max-w-[680px]">
              <p>{postLang["isa-h1-txt"]}{postLang["isa-h1-txt_2"]}</p>
            </div>
          </div>
        </div>
      </div>
    <div className="max-w-xl mx-auto  pt-0 md:pt-5 pb-11 md:max-w-5xl px-6 mb-8 md:mb-10">
        <div className="text-left md:mt-7 mt-10 mb-10">
          <h2 className="font-semibold  tracking-wide text-[26px] md:text-[40px]">{postLang["isa-h2_2"]}</h2>
        </div>
        <h3 className="font-semibold border-l-2 border-[#067CFA] pl-2.5 mb-6 text-lg">{postLang["isa-h2_"]}</h3>
        <p className=" mb-10 ">{postLang["isa-txt_3"]}<br></br>{postLang["isa-txt_4"]}</p>
      
        <h3 className="font-semibold border-l-2 border-[#067CFA] pl-2.5 mb-6 text-lg">{postLang["isa-h4_2"]}</h3>
        <p className=" mb-10 ">{postLang["isa-txt_11"]}<br></br>{postLang["isa-txt_4"]}<br></br>{postLang["isa-txt_12"]}<a href="https://miltonprime.com/" target="_blank" rel="noreferrer" className="underline" >{postLang["isa-txt_5-1"]}</a>{postLang["isa-txt_5-2"]}</p>
      
        <h3 className="font-semibold border-l-2 border-[#067CFA] pl-2.5 mb-6 text-lg">{postLang["isa-h4_5"]}</h3>
        <p className=" mb-10 ">{postLang["isa-txt_13"]}<br></br>{postLang["isa-txt_14"]}<br></br>{postLang["isa-txt_15"]}</p>
           <ul>
            <li className="ml-5 md:mb-[24px] list-disc">
            <a className="hover:no-underline underline" href={postLang["isa_link1"]}> {postLang["isa-txt_16"]}</a> {postLang["isa-txt_17"]}
            </li>
            <li className="ml-5 md:mb-[24px] list-disc">
            {postLang["isa-txt_19"]}
            </li>
            <li className="ml-5 md:mb-[24px] list-disc">
            {postLang["isa-txt_20"]}
            </li>
          </ul>
        <div className="text-left md:mt-12 mb-7">
          <h2 className="font-semibold  tracking-wide text-[26px] md:text-[40px] mb-5">{postLang["isa-txt_9"]}</h2>
        </div>
        <p className="mb-10">{postLang["isa-txt_10"]}</p>

        <h2 className="font-semibold  tracking-wide text-[26px] md:text-[40px] mb-5">{postLang["isa-h2_5"]}</h2>
        <p  className="mb-10"> {postLang["isa-txt_20"]}</p>

        <ul className="mb-12">
            <li className="ml-5 md:mb-[24px] list-disc">
            <a className="hover:no-underline underline" href={postLang["isa_link2"]}>{postLang["isa-txt_21"]}</a> {postLang["isa-txt_22"]}
            </li>
            <li className="ml-5 md:mb-[24px] list-disc">
            {postLang["isa-txt_23"]}
            </li>
            <li className="ml-5 md:mb-[24px] list-disc">
            {postLang["isa-txt_24"]}

            </li>
          </ul>

          <h2 className="font-semibold  tracking-wide text-[26px] md:text-[40px] mb-5">{postLang["isa-h2_6"]}</h2>
          <p  className="mb-10">{postLang["isa-txt_25"]}<a className="hover:no-underline underline" href="/ja/execution-conditions/">{postLang["isa-txt_26"]}</a>{postLang["isa-txt_27"]}<br>
          </br>{postLang["isa-txt_28"]}</p>

          <h2 className="font-semibold  tracking-wide text-[26px] md:text-[40px] mb-5">{postLang["isa-h2_7"]}</h2>
          <p  className="mb-10">{postLang["isa-txt_29"]}<br></br>
          {postLang["isa-txt_30"]}<br></br>{postLang["isa-txt_31"]}<a href={postLang["isa_link3"]}>{postLang["isa-txt_32"]}</a>
          </p>
    </div>


      <div className="Feature bg-fixed">
            <div className="max-w-xl mx-auto md:max-w-5xl px-6 ">
            <div className="mx-auto px-6 flex max-w-xl flex-wrap justify-between md:max-w-5xl"> </div>
           </div>
            <div className="py-14 py-14  md:py-10 md:py-10"> 
              <div className="md:mx-auto max-w-5xl flex justify-center"><a href={signPath1} className="cont_regi01 rounded block md:max-w-xs mx-5  text-center text-white font-bold  px-16 my-2 bg-black hover:scale-[1.05] pt-[18px] pb-[20px] duration-300">{postLang["open"]}</a> </div>
           </div>
      </div>
    </Layout>
  </>
  )
}
export default Regulation